import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  ContentWrapper,
  Title,
  Description,
  ImageWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import ImageSlider from "../../../../../common/ImageSlider";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface CSRProps {
  pageChange: PageChange;
}

const CSR: React.FC<CSRProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const data = useStaticQuery(imagesQuery);
  const fullPageImages = [0, 1, 2];

  return (
    <>
      <PageTitle subtitle={t(`CSR_title`)} />
      <PageWrapper animation={pageChange}>
        <ContentWrapper>
          <Title>{t(`CSR_title`)}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(
                t(`CSR_description`)
              ),
            }}
          />
        </ContentWrapper>
        <ImageWrapper>
          <ImageSlider
            images={data && data.allFile.nodes}
            imageFitStyleArray={[
              ...fullPageImages.map(f => "cover"),
              ...data.allFile.nodes
                .filter((_: any, i: number) => !fullPageImages.includes(i))
                .map((_: any) => "contain"),
            ]}
          />
        </ImageWrapper>
      </PageWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "CSR" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default CSR;

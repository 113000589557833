import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H2,
  H3,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    flex-direction: row;
  } ;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #ffffff;
  padding: 88px 178px 40px 88px;

  @media ${device.tabletLandscape} {
    width: 428px;
    padding: 88px 64px;
  }

  @media ${device.computer} {
    width: 603px;
    padding: 128px 64px 120px 120px;
  }

  @media ${device.desktop} {
    width: 833px;
    padding: 128px 174px 64px 120px;
  } ;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  line-height: 36px;
  font-weight: 800;
  color: #013cff;

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  }
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin-top: 6vh;
  color: #151515;
  white-space: pre-line;

  @media ${device.computer} {
  }

  a,
  a:visited,
  a:link {
    color: #013cff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 68vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${device.tabletLandscape} {
    position: absolute;
    width: calc(100% - 428px);
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media ${device.computer} {
    position: absolute;
    width: calc(100% - 603px);
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media ${device.desktop} {
    width: calc(100% - 833px);
  }
`;
